import React from "react";

const MainLayout = ({ children }) => {

  return (
    <div className="bg-primary overflow-x-hidden max-w-[100%] p-0 m-0">
      {children}
    </div>
  );
};

export default MainLayout;

