import React from "react";
import "./App.css";
import Home from "pages/Home"
import ReactGA from "react-ga4";

ReactGA.initialize("G-KDKCJ6C0V0");

const App = () => {

  return (
    <div className="App">
      <Home />
    </div>
  );
};

export default App;
