import React from 'react';
import Layout from "layout/MainLayout";
import { useWindowSize } from 'react-use'

const Home = () => {
  const imgUrl = process.env.PUBLIC_URL;
  const { width } = useWindowSize()


  const handleRegister = () => {
    // Open the desired URL in a new tab
    window.open('https://3m5imjmgtmu.typeform.com/to/MrsDVJlg', '_blank');
  };

  const handleBlog = () => {
    // Open the desired URL in a new tab
    window.open('https://agileloop.ai/understanding-large-action-models-paving-the-way-for-action-oriented-ai/', '_blank');
  };

  const handleGit = () => {
    // Open the desired URL in a new tab
    window.open('https://github.com/Agile-Loop/Synapse-Copilot', '_blank');
  };

  const handleReaserch = () => {
    // Open the desired URL in a new tab
    window.open('https://arxiv.org/abs/2404.08755', '_blank');
  };

  const handleDiscord = () => {
    // Open the desired URL in a new tab
    window.open('https://discord.gg/jJCZvrqK2D', '_blank');
  };


  const handleClick = (type) => {
    if(type === 1){
      window.location.href = 'mailto:developer@agileloop.ai';
    }
    if(type === 2){
      window.open('https://agileloop.ai/', '_blank');
    }
    if(type === 3){
      window.open('https://www.linkedin.com/company/agileloop/agileloop.ai', '_blank');
    }
  }


  return (
    <Layout>
      {/**First Sec */}
      <div className='flex flex-col justify-center items-center pt-[150px] xs:mt-0 xs:pt-[80px]'>
        <div className='flex justify-center items-center'>
          <p className='text-[20px]  font-[400px] hover:text-[#7214FF]  transition-transform transform hover:scale-105  font-Mont cursor-pointer text-white'>Home</p>
          <p className='text-[20px] font-[400px] hover:text-[#7214FF] transition-transform transform hover:scale-105 font-Mont cursor-pointer text-white ml-5' onClick={handleGit}>Github</p>
          <p className='text-[20px] font-[400px] hover:text-[#7214FF]  transition-transform transform hover:scale-105 font-Mont cursor-pointer text-white ml-5' onClick={handleBlog}>Blog</p>
        </div>
        <img src={imgUrl + "logoWhite.png"} alt="logo" className='w-[300px] xs:w-[220px] h-[75px] xs:h-[60px] mt-[80px]' />
        <p className='text-[48px] xs:text-[30px] font-[400px] font-Mont  mt-4 bg-gradient-to-b from-[#F6F6F7] to-[#7E808F] bg-clip-text text-transparent text-center'>HACK THE LOOP</p>
        <p className='text-[24px] xs:text-[18px] font-[400px] font-Mont bg-gradient-to-b from-[#F6F6F7] to-[#7E808F] bg-clip-text text-transparent text-center mt-4'>May 27th - 6th June 2024</p>
        <p className='text-[32px] xs:text-[20px] font-[400px] font-Mont bg-gradient-to-b from-[#F6F6F7] to-[#7E808F] bg-clip-text text-transparent text-center mt-1'>Actionable AI Hackathon 2024!</p>
        <button onClick={handleRegister} className='bg-btn transition-transform transform hover:scale-105 text-white rounded-full text-[16px] font-normal font-Mont px-[25px] py-[15px] my-5 hover:font-bold'>REGISTER HERE</button>
      </div>
      <img src={imgUrl + "circle1.svg"} alt="circle" className='my-10 mx-auto object-cover' />
      {/**2end Sec */}
      <div className='mt-5'>
        <p className='text-[48px] xs:text-[30px] font-[400px] bg-gradient-to-b from-[#F6F6F7] to-[#7E808F] bg-clip-text text-transparent text-center'>“The Rise of Large Action Models” </p>
        <div className='2xl:w-[45%] xl:w-[60%] lg:w-[70%] md:w-[80%] sm:w-[80%] xs:w-[90%] mx-auto mt-[70px] grid grid-cols-2 xs:grid-cols-1 gap-10'>
          <div className='bg-action1 rounded-[10px] p-5' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
            <img src={imgUrl + "cups.png"} alt="action1 " className='bg-center object-cover h-[270px] w-[240px] mx-auto' />
            <p className='text-[24px] xs:text-[18px] font-[400px] text-center font-Mont cursor-pointer text-white'>
              Win up to <b>$100,000</b> in prizes.
            </p>
          </div>
          <div className='rounded-[10px] p-5 bg-card' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
            <img  src={imgUrl + "n2.png"} alt="action2" className=' mx-auto cursor-pointer bg-center object-contain h-[270px] w-[240px] ' />
            <p className='text-[24px] xs:text-[18px] font-[400px] text-center font-Mont cursor-pointer text-white'>
            Exclusive <b className=''>job offers</b> for top participants.

            </p>
          </div>
        </div>
        <p className='mt-8 2xl:w-[45%] xl:w-[60%] lg:w-[70%] md:w-[80%] sm:w-[80%] xs:w-[90%] mx-auto font-Mont text-[#E2E2E2]'>*Details of the prize distribution to be announced 2 weeks prior to the hackathon</p>
      </div>
      {/**3end Sec */}
      <div className='mt-[100px] xs:mt-[50px] pb-[50px]'>
        <p className='text-[38px] xs:text-[30px] font-[400px] font-Mont bg-gradient-to-b from-[#F6F6F7] to-[#7E808F] bg-clip-text text-transparent text-center'>OUR PARTNERS </p>
        <p className='text-[20px] xs:text-[16px] font-[400px] font-Mont text-[#E2E2E2] text-center mx-auto mt-5 
        2xl:w-[50%] xl:w-[50%] lg:w-[65%] md:w-[80%] sm:w-[90%] xs:w-[90%] '>
          Excited to partner with Lablab ai and NVIDIA Inception program for our hackathon! Let's create together!
        </p>
        <div className='mt-[50px] flex items-center justify-center
        '>
          {/* <img src={imgUrl + "p1.png"} alt="p1" className='col-span-2 w-full object-cover' />
          <img src={imgUrl + "p2.png"} alt="p1" className='col-span-1 w-full object-cover' /> */}
          {/* <img src={imgUrl + "n3.png"} alt="p1" className=' w-[260px] xs:w-[45%] object-cover' /> */}
          <img src={imgUrl + "p4.png"} alt="p1" className='w-[260px] xs:w-[50%] object-cover' />
        </div>
      </div>
      {/**4th Sec */}
      <>
        <div className='relative py-[80px] xs:py-[50px]'>
          <img src={imgUrl + "circle2.png"} alt="@" className='absolute left-0 2xl:block xl:block lg:block md:hidden sm:hidden xs:hidden top-0 object-cover' />
          
          <p className=' text-[38px] xs:text-[30px] xs:w-[90%] xs:mx-auto font-[400px] font-Mont bg-gradient-to-b from-[#F6F6F7] to-[#7E808F] bg-clip-text text-transparent text-center'>Your Virtual Hackathon Journey</p>
          <div className=' grid gap-5  relative  mx-auto mt-[70px]
          2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2  xs:grid-cols-1
                    2xl:w-[55%] xl:w-[65%] lg:w-[70%] md:w-[90%] sm:w-[90%] xs:w-[90%]'>
          <img src={imgUrl + "circle3.png"} alt="@" className='2xl:flex xl:flex lg:hidden md:hidden sm:hidden xs:hidden absolute -right-[220px] -bottom-[50px] object-cover' />
            <div className='z-50 col-span-1 h-[260px] relative pt-5  bg-card px-5 rounded-[4px]' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
              <img alt="@" src={imgUrl + "i1.svg"} />
              {/* <p className='text-[20px] font-semibold  font-Mont text-white mt-5'>Register</p> */}
              <p className='2xl:text-[18px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[16] xs:text-[16px]  font-[400px] font-Mont text-white mt-5'>Follow the Registration steps to save your spot.</p>
              <button onClick={handleRegister} className='bg-btn absolute right-5 bottom-0 text-white rounded-full text-[14px]    transition-transform transform hover:scale-105  font-normal font-Mont px-[15px] py-[10px] my-5 hover:font-bold'>REGISTER HERE</button>
            </div>
            <div className='col-span-1 h-[260px] relative pt-5 bg-card  px-5 rounded-[4px]' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
              <img alt="@" src={imgUrl + "i2.png"} className='rounded-lg'/>
              <p className='2xl:text-[18px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[16] xs:text-[16px] font-[400px] font-Mont text-white mt-5'>Join our Github Repository & Star the Repository.</p>
              <button onClick={handleGit} className='bg-btn absolute right-5 bottom-0 text-white rounded-full text-[14px]  transition-transform transform hover:scale-105  font-normal font-Mont px-[15px] py-[10px] my-5 hover:font-bold'>GO TO GITHUB</button>
            </div>
            <div className='col-span-1 h-[260px] pt-5 bg-card  px-5 rounded-[4px]' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
              <img alt="@" src={imgUrl + "i3.png"} />
              <p className='2xl:text-[18px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[16] xs:text-[16px] mt-5 font-[400px] font-Mont text-white'>
                Receive your Onboarding Kit & Book sessions with your mentors.
              </p>
            </div>
            <div className='col-span-1 h-[260px] pt-5 bg-card  px-5 rounded-[4px]' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
              <img alt="@" src={imgUrl + "i4.svg"} />
              <p className='2xl:text-[18px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[16] xs:text-[16px] mt-5 font-[400px] font-Mont text-white'>
                Join your Virtual Space.
              </p>
            </div>
            <div className='col-span-1 h-[260px] pt-5 bg-card  px-5 rounded-[4px]' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
              <img alt="@" src={imgUrl + "i5.svg"} />
              <p className='2xl:text-[18px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[16] xs:text-[16px] mt-5 font-[400px] font-Mont text-white'>
              Attend our opening ceremony & Kick-off.
              </p>
            </div>
            <div className=' col-span-1 h-[260px] pt-5 bg-card  px-5 rounded-[4px]' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
              <img alt="@" src={imgUrl + "i6.png"} />
              <p className='2xl:text-[18px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[16] xs:text-[16px] mt-5 font-[400px] font-Mont text-white'>
              Present your work to our judges for evaluation.
              </p>
            </div>
          </div>
        </div>
        <div className=' pb-[20px]'>
          <p className='text-[38px] xs:text-[30px] w-[90%] mx-auto font-[400px] font-Mont text-center bg-gradient-to-b from-[#F6F6F7] to-[#7E808F] bg-clip-text text-transparent'>
          Calling all innovators aiming to crack <b>AGI</b>
          </p>
          <div className='grid gap-5 grid-cols-2 xs:grid-cols-1 mx-auto mt-[70px] relative 
          2xl:w-[35%] xl:w-[45%] lg:w-[50%] md:w-[70%] sm:w-[90%] xs:w-[90%]'>
            <div className='col-span-1 h-[260px] pt-5 bg-card  px-5 rounded-[4px]' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
              <img alt="@" src={imgUrl + "i7.png"} />
              <p className='2xl:text-[18px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[16] xs:text-[16px] mt-5 font-[400px] font-Mont text-white'>
                10 Days of Coding, Collaborating, and Creating.
              </p>
            </div>
            <div className='col-span-1 h-[260px] pt-5 bg-card  px-5 rounded-[4px]' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
              <img alt="@" src={imgUrl + "i8.svg"} />
              <p className='2xl:text-[18px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[16] xs:text-[16px] mt-5 font-[400px] font-Mont text-white'>
                Open to Developers & Innovators of all Levels.
              </p>
            </div>
          </div>
          <div className='2xl:w-[50%] xl:w-[60%] lg:w-[65%] md:w-[85%] mx-auto'>
            <img src={imgUrl + "circle4.png"} alt="@" className='object-cover 2xl:block xl:block lg:block md:block sm:hidden xs:hidden' />
          </div>
        </div>
      </>
      {/**addition Sec */}
      <div className='pb-[20px] -mt-5 xs:mt-10'>
        <div className='bg-card flex flex-col items-center justify-center rounded-lg 2xl:w-[20%] xl:w-[25%] lg:w-[30%] md:w-[40%] sm:w-[90%] xs:w-[90%]  p-8 mx-auto ' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
          <img onClick={handleBlog} alt="@" src={imgUrl + "action2.png"} className='cursor-pointer object-cover h-[240px] transition-transform transform hover:scale-105'/>
          <p className='
          2xl:text-[20px] mt-3 text-white font-Mont xl:text-[18px] lg:text-[16px] md:text-[16px] sm:text-[16px] xs:text-[16px]'>
            Read about <b>Large Action Models.</b></p>
        </div>
        <div className='2xl:w-[90%] xl:w-[90%] lg:w-[90%] md:w-[90%] sm:w-[90%] xs:w-[90%]
        2xl:-mt-[60px] xl:-mt-[60px] lg:-mt-[60px] md:-mt-[60px] sm:mt-[20px] xs:mt-[20px]
        flex 2xl:justify-end xl:justify-end lg:justify-end md:justify-end sm:justify-center xs:justify-center'>
          <img alt="@" src={imgUrl + "n4.png"} className='object-cover'/>
        </div>
      </div>
      {/**5th Sec */}
      <div className='pb-[200px] xs:pb-[50px]'>
        <div className='pb-[15px]'>
          <p className='text-[38px] xs:text-[30px] w-[90%] mx-auto font-[400px] bg-gradient-to-b from-[#F6F6F7] to-[#7E808F] bg-clip-text text-transparent font-Mont text-center'>Let’s teach AI how to run software for humans</p>
          <p className='text-[32px] xs:text-[20px] w-[90%] mx-auto font-[400px] mt-2 font-Mont bg-gradient-to-b from-[#F6F6F7] to-[#7E808F] bg-clip-text text-transparent text-center'>Foundation Models APIs </p>
          <div className=' grid gap-5  mx-auto mt-[70px]
          2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 xs:grid-cols-1
          2xl:w-[60%] xl:w-[70%] lg:w-[90%] md:w-[90%] sm:w-[90%] xs:w-[90%]'>
            <div className='col-span-1  bg-card  p-5 rounded-[4px] min-h-[260px]' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
              <p className='2xl:text-[24px] xl:text-[20px] lg:text-[20px] md:text-[20px] sm:text-[20] xs:text-[20px] font-semibold font-Mont text-white'>Synapse</p>
              <p className='2xl:text-[18px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[16] xs:text-[16px] mt-5 font-[400px] font-Mont text-white'>
                A Co-pilot for any SaaS Product. Talks to your product APIs and automates your tasks on Zoho, Trello, Jira, Salesforce & many more.
              </p>
            </div>
            <div className='col-span-1  bg-card  p-5 rounded-[4px] min-h-[260px]' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
              <p className='2xl:text-[24px] xl:text-[20px] lg:text-[20px] md:text-[20px] sm:text-[20] xs:text-[20px] font-semibold font-Mont text-white'> Cognitive VLM</p>
              <p className='2xl:text-[18px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[16] xs:text-[16px] mt-5 font-[400px] font-Mont text-white'>
                An Intelligent Visual Language Model that understands any software interface it sees & tells you what actions to take.
              </p>
            </div>
            <div className='col-span-1  bg-card  p-5 rounded-[4px] min-h-[260px]' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
              <p className='2xl:text-[24px] xl:text-[20px] lg:text-[20px] md:text-[20px] sm:text-[20] xs:text-[20px] font-semibold font-Mont text-white'>Action Annotator</p>
              <p className='2xl:text-[18px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[16] xs:text-[16px] mt-5 font-[400px] font-Mont text-white'>
                Records your actions on software screens and repeats it for you.
              </p>
            </div>
          </div>
        </div>
        <div className='bg-card xs:bg-none mt-[70px]  pb-10 rounded-lg  mx-auto 
        2xl:px-10 xl:px-10 lg:px-5 md:px-5 sm:px-3 xs:px-0
        2xl:w-[60%] xl:w-[80%] lg:w-[90%] md:w-[90%] sm:w-[90%] xs:w-[90%]' 
        style={{ border: width > 500 && "1px solid var(--Neutral-gray-500, #282D45)" }}>
          <div className='flex xs:flex-wrap justify-between items-center'>
            <div className='2xl:min-w-[50%] 2xl:max-w-[50%] xl:min-w-[50%] lg:min-w-[50%] md:min-w-[100%]'>
              <p className='text-[28px] xs:text-[20px] mt-5 font-bold font-Mont text-white'>
                Get noticed by industry leading judges!
              </p>
              <p className='text-[20px] xs:text-[16px] mt-5 font-normal font-Mont text-white'>
                Check out the latest research paper published by a few of our judges, on action models.
              </p>
              <img alt="@" src={imgUrl + "arxiv.png"} className='object-cover mt-4' />
              <button onClick={handleReaserch} className='bg-transparent text-white rounded-full text-[16px]   transition-transform transform hover:scale-105 font-normal font-Mont px-3 py-2 mt-4 hover:font-bold' style={{ border: "1.2px solid #282D45" }}>Read Research Paper</button>
            </div>
            <img src={imgUrl + "circle5.png"} alt="circle" className='object-cover xs:mt-10 2xl:block xl:block lg:block md:hidden sm:hidden xs:block' />
          </div>
          <div className='grid  mx-auto gap-5 -mt-[60px] xs:mt-5
          2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 xs:grid-cols-1' >
            <div className='bg-card h-[450px] flex flex-col relative items-center rounded-lg' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
              <img src={imgUrl + "per1.svg"} alt="@" className='w-[250px] h-[250px] mt-5 object-cover' />
              <p className='text-white font-bold text-[18px] font-Mont mt-3'>Dr. Janusz Marecki</p>
              <p className='text-white font-normal text-[16px] font-Mont'>Ex Lead Researcher</p>
              <img alt="@" src={imgUrl + "deepMind.png"} className='w-[250px] object-cover absolute bottom-5' />
            </div>
            <div className='bg-card h-[450px] flex flex-col items-center rounded-lg relative' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
              <img src={imgUrl + "per2.png"} alt="@" className='w-[250px] h-[250px] mt-5 object-cover' />
              <p className='text-white font-bold text-[18px] font-Mont mt-3'>Ammar Anwar</p>
              <p className='text-white font-normal text-[16px] font-Mont'>Ex Senior Researcher</p>
              <img alt="@" src={imgUrl + "uni.png"} className='w-[250px] object-cover absolute bottom-5' />
            </div>
            <div className='bg-card h-[450px] relative flex flex-col items-center rounded-lg' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
              <img src={imgUrl + "per3.png"} alt="@" className='w-[250px] h-[250px] mt-5 object-cover' />
              <p className='text-white font-bold text-[18px] font-Mont mt-3'>Maciej Szczepaniak</p>
              <p className='text-white font-normal text-[16px] font-Mont'>Resident Advisor</p>
              <p className='text-white font-normal text-[16px] font-Mont'>Ex Senior Global Product lead</p>
              <div className='flex items-center justify-start absolute bottom-5 left-5'>
                <img alt="@" src={imgUrl + "google.png"} className='object-cover w-[50px]' />
                <div className='ml-5 bg-white rounded-full flex w-[50px] h-[50px] items-center justify-center'>
                  <img alt="@" src={imgUrl + "five.png"} className='object-cover' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/**6th Sec */}
      <div
        style={{ backgroundImage: 'url("/bgs.jpg")' }}
        className=' bg-center bg-cover 2xl:w-[50%] xl:w-[60%] lg:w-[70%] md:w-[80%] sm:w-[90%] xs:w-[90%] h-[450px] mx-auto rounded-lg  flex flex-col justify-center items-center relative'>
        <img alt="cir6" src={imgUrl + "circle6.png"} className='absolute -right-[150px] top-6 object-cover 2xl:block xl:block lg:block md:hidden sm:hidden xs:hidden' />
        <img alt="game" src={imgUrl + "game.png"} className='object-cover ' />
        <p className='font-Mont text-white 2xl:text-[28px] xl:text-[24px] lg:text-[24px] md:text-[24px] sm:text-[24] xs:text-[20px] 2xl:w-[50%] xl:w-[50%] lg:w-[80%] md:w-[90%] sm:w-[90%] xs:w-[95%] text-center font-bold mt-5'>Join the community to stay tuned</p>
        <p className='font-Mont text-white text-[18px] xs:text-[16px] 2xl:w-[50%] xl:w-[50%] lg:w-[80%] md:w-[90%] sm:w-[90%] text-center font-normal mt-3'>Collaborate, Communicate, Create: Join our Discord server!</p>
        <button onClick={handleDiscord} className='bg-btn text-white rounded-full text-[16px] font-normal font-Mont px-[25px] py-[15px] mt-5  transition-transform transform hover:scale-105 hover:font-bold'>Join Discord</button>
      </div>
      {/**last Sec */}
      <div className='pt-[70px] xs:pb-10 2xl:w-[50%] xl:w-[60%] lg:w-[70%] md:w-[80%] sm:w-[90%] xs:w-[90%] mx-auto'  >
        <div className='bg-center py-[100px] xs:py-[50px] bg-cover ' style={{ backgroundImage: 'url("/footerBg.png")' }}>
          <div className='bg-card w-[350px]  xs:w-full flex flex-col px-5 pb-5 rounded-xl' style={{ border: "1px solid var(--Neutral-gray-500, #282D45)" }}>
            <p className='mt-5 text-white font-Mont font-bold text-[24px]'>Contact us</p>
            <p onClick={()=>handleClick(1)} className='mt-3 text-[#473BF0] cursor-pointer text-[14px] font-Mont font-bold'>developer@agileloop.ai</p>
            <p onClick={()=>handleClick(2)} className='mt-1 text-[#473BF0] cursor-pointer text-[14px] font-Mont font-bold'>agileloop.ai</p>
            <p onClick={()=>handleClick(3)} className='mt-1 text-[#473BF0] cursor-pointer text-[14px] truncate  font-Mont font-bold'>https://www.linkedin.com/company/agileloop/agileloop.ai</p>
          </div>
        </div>

      </div>
      {/**widget Sec */}
      <div  className='z-150 xs:px-5 px-10 bg-widget flex justify-between items-center w-[330px] xs:w-[90%] h-[70px] xs:h-[55px] rounded-full fixed bottom-5 left-1/2 transform -translate-x-1/2'>
        <p onClick={handleRegister} className='text-white cursor-pointer font-bold font-Mont text-[16px]  transition-transform transform hover:scale-105'>REGISTER</p>
        <div className='flex items-center'>
          <div className='bg-white w-[50px] xs:w-[40px] xs:h-[40px] cursor-pointer  h-[50px] rounded-full p-2 transition-transform transform hover:scale-90'>
            <img onClick={handleDiscord} alt='@' src={imgUrl + "logosm.png"} className='object-cover mt-[2px]' />
          </div>
          <div className='bg-white xs:w-[40px] xs:h-[40px] w-[50px] ml-5 h-[50px] rounded-full p-2 cursor-pointer transition-transform transform hover:scale-90' onClick={handleGit}>
            <img alt='@' src={imgUrl + "github.png"} className='object-cover' />
          </div>

        </div>
      </div>
    </Layout>
  )
}

export default Home